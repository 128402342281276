import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CXL } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "../MarketingLink";

type StyleProps = {
  colour?: string;
  fadedColour?: string;
  darkText?: boolean;
  faded?: boolean;
};

const pillarsList = [
  {
    title: { bold: "The Money", normal: "agenda" },
    copy: "Guiding you and your finances through macro-economic challenges",
    colour: "#336C5E",
    fadedColour: "#A4DCCF",
    darkText: true,
  },
  {
    title: { bold: "Step", normal: "forward" },
    copy: "Giving you the tools to confidently navigate your career in a constantly changing world",
    colour: "#EA7B6E",
    fadedColour: "#FFBEA2",
    darkText: true,
  },
  {
    title: { bold: "Lead", normal: "on" },
    copy: "From innovations in tech to sustainability, we empower business leaders in an ever-evolving landscape",
    colour: "#272434",
    fadedColour: "#4D4865",
  },
  {
    title: { bold: "Live", normal: "well" },
    copy: "Encouraging you to unplug, recharge, and prioritise self-care beyond the workplace",
    colour: "#FFA86F",
    fadedColour: "#FFD59F",
    darkText: true,
  },
];

const AllBrightPillarsContent: FunctionComponent = () => {
  return (
    <S.Section>
      <S.ContentContainer>
        <S.Title>
          The{" "}
          <S.RoxboroughFontWrap>
            <i>AllBright</i> pillars
          </S.RoxboroughFontWrap>
        </S.Title>
        <S.CopyContainer>
          <CXL weight="500">
            Whether it's online or in-person, we're dedicated to producing an
            enriching programme of events and content designed to support our
            community at every stage of their careers and personal lives.
          </CXL>
          <CXL weight="500">
            Split into five key pillars, there is something for every woman, no
            matter her ambition.
          </CXL>
        </S.CopyContainer>
        <S.PillarsContainer>
          {pillarsList.map((pillar) => (
            <S.Pillar darkText={pillar.darkText} key={pillar.title.bold}>
              <S.PillarSquare colour={pillar.colour}>
                <S.PillarTitle>
                  {pillar.title.bold} <br />
                  <S.RoxboroughFontWrap>
                    <i>{pillar.title.normal}</i>
                  </S.RoxboroughFontWrap>
                </S.PillarTitle>
              </S.PillarSquare>
              <S.PillarSquare faded fadedColour={pillar.fadedColour}>
                <CXL>{pillar.copy}</CXL>
              </S.PillarSquare>
            </S.Pillar>
          ))}
        </S.PillarsContainer>
        <S.LinksContainer>
          <MarketingLink
            href="/business"
            colour={colours.charcoal}
            textColour={colours.charcoal}
            animationColour={colours.marketingOrange}
            linkText="For Business"
          />
          <MarketingLink
            href="/individuals"
            colour={colours.charcoal}
            textColour={colours.charcoal}
            animationColour={colours.marketingOrange}
            linkText="For Individuals"
          />
        </S.LinksContainer>
      </S.ContentContainer>
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section`
  width: 100%;
  padding: 10%;
  background: white;

  @media (min-width: ${Device.tablet}) {
    padding: 5% 10%;
  }
`;

S.Title = styled.h3`
  font-weight: 800;
  font-size: 4rem;
  position: relative;
  line-height: 3.2rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 5rem;
    line-height: 4rem;
  }

  @media (min-width: ${Device.tablet}) {
    line-height: 4rem;
  }
`;

S.RoxboroughFontWrap = styled.span`
  font-family:
    Roxborough CF,
    sans-serif;
  font-weight: 100;
`;

S.ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${Device.desktop}) {
    gap: 40px;
  }
`;

S.CopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  @media (min-width: ${Device.desktop}) {
    width: 50%;
  }
`;

S.PillarsContainer = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${Device.desktop}) {
    flex-direction: row;
    gap: 20px;
  }
`;

S.Pillar = styled.li<StyleProps>`
  color: ${(p) => (p.darkText ? colours.charcoal : colours.shell)};
  width: 100%;
  list-style-type: none;
  display: flex;
  justify-content: center;

  @media (min-width: ${Device.desktop}) {
    flex-direction: column;
    text-align: center;
  }
`;

S.PillarSquare = styled.div<StyleProps>`
  width: 50%;
  max-width: 200px;
  aspect-ratio: 1;

  @supports not (aspect-ratio: 1) {
    &::before {
      float: left;
      padding-top: 100%;
      content: "";
    }
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  padding: 5%;
  background: ${(p) => (p.faded ? p.fadedColour : p.colour)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${Device.desktop}) {
    width: 100%;
    max-width: 100%;
  }
`;

S.PillarTitle = styled.p`
  color: ${colours.shell};
  text-align: center;
  font-weight: 800;
  font-size: 2.5rem;

  @media (min-width: ${Device.mobile}) {
    font-size: 3.5rem;
  }

  @media (min-width: ${Device.mobile}) {
    font-size: 3.5rem;
  }
`;

S.LinksContainer = styled.div`
  grid-area: links;
  padding: 0 0 10px 0;
  display: flex;
  gap: 40px;

  @media (min-width: ${Device.mobile}) {
    padding-top: 2rem;
  }
`;

export default AllBrightPillarsContent;
